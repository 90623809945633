<template>
  <div>
    <v-row
      class='px-2'
      no-gutters
    >
      <v-col
        cols='12'
        lg='5'
      >
        <v-autocomplete
          v-model='coursegroup_id'
          :items='dataCourseGroupList'
          item-text='coursegroup_name'
          item-value='coursegroup_id'
          dense
          hide-details
          class='my-2 mx-1'
          :label="$t('Course_Gategory')"
          outlined
        ></v-autocomplete>
      </v-col>
      <v-col
        cols='12'
        lg='7'
      >
        <v-text-field
          v-model='searchtext'
          hide-details
          :placeholder="`${$t('code')}, ${$t('name')} ${$t('course')}`"
          class='my-2 mx-1'
          outlined
          dense
          :label="$t('serachCourse')"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      disable-filtering
      :headers='columns'
      mobile-breakpoint='0'
      :items='dataCourseList'
      :options.sync='options'
      :server-items-length='totalDataTableList'
      :footer-props='footer'
      :loading='loading'
      dense
      height='465'
      disable-sort
      :loading-text="$t('data_loading')"
      :no-data-text="$t('no_information')"
    >
      <template v-slot:[`item.course_price`]='{ item }'>
        {{ item.course_price| formatPrice }}
      </template>

      <template v-slot:[`item.course_name`]='{ item }'>
        <v-chip
          v-if="coursegroup_id === 'set'"
          color='info'
          :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg info--text`"
          small
        >
          <span>
            {{ $t('set') }}
          </span>
        </v-chip>
        <v-icon
          v-if="item.course_type == '1'"
          small
          color='primary'
        >
          {{ icons.mdiTimetable }}
        </v-icon>
        <v-icon
          v-if="item.course_type == '2'"
          small
          color='warning'
        >
          {{ icons.mdiClockOutline }}
        </v-icon>
        <v-icon

          v-if="item.course_type == '3'"
          small
          color='accent'
        >
          {{ icons.mdiPill }}
        </v-icon>
        {{ item.course_name }}
      </template>

      <template v-slot:[`item.actions`]='{ item }'>
        <v-tooltip
          top
          color='#212121'
        >
          <template v-slot:activator='{ on, attrs }'>
            <v-btn
              color='primary'
              icon
              v-bind='attrs'
              v-on='on'
              @click='selectCourse(item)'
            >
              <v-icon>{{ icons.mdiCheckCircleOutline }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('select') }}</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog
      v-model='isSelectCourse'
      max-width='400'
      persistent
    >
      <v-card>
        <v-card-title class='text-subtitle-1'>
          <v-chip
            v-if="coursegroup_id === 'set'"
            color='info'
            :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg info--text`"
            small
          >
          <span>
            {{ $t('courseSet') }}
          </span>
          </v-chip>
          <CourseType
            v-else
            :type='objCoruseSelect.course_type'
            class='mb-1'
          />
          ({{ objCoruseSelect.course_id }}) {{ objCoruseSelect.course_name }}
          {{ objCoruseSelect.course_amount }} {{ objCoruseSelect.course_unit }}
          <span class='primary--text'>{{ $t('price') }} <span
            class='error--text'> {{ objCoruseSelect.course_price }}</span> {{ $t('THB') }}</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols='12'>
              <v-row
                align='center'
                justify='center'
              >
                <v-col cols='6'>
                  <span class='font-weight-semibold'>
                    {{ $t('a_price_unit') }}
                  </span>
                </v-col>
                <v-col cols='6'>
                  <v-text-field
                    v-model='coursePrice'
                    outlined
                    hide-details
                    reverse
                    :disabled="coursegroup_id==='set'"
                    dense
                    type='number'
                    @input='handleChangeCourse'
                  >
                    <template v-slot:prepend>
                      <v-icon
                        color='primary'
                        @click='
                          coursePrice > 0 ? coursePrice-- : (coursePrice = 0),
                          handleChangeCourse()
                        '
                      >
                        {{ icons.mdiMinus }}
                      </v-icon>
                    </template>
                    <template v-slot:append-outer>
                      <v-icon
                        color='primary'
                        @click='coursePrice++, handleChangeCourse()'
                      >
                        {{ icons.mdiPlus }}
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row
                align='center'
                justify='center'
              >
                <v-col cols='6'>
                  <span class='font-weight-semibold'>
                    {{ $t('discount_unit') }}
                  </span>
                </v-col>
                <v-col cols='6'>
                  <v-text-field
                    v-model='courseDiscount'
                    outlined
                    :disabled="coursegroup_id==='set'"
                    hide-details
                    reverse
                    dense
                    type='number'
                    @input='handleChangeCourse'
                  >
                    <template v-slot:prepend>
                      <v-icon
                        color='primary'
                        @click='
                          courseDiscount > 0 ? courseDiscount-- : (courseDiscount = 0),
                          handleChangeCourse()
                        '
                      >
                        {{ icons.mdiMinus }}
                      </v-icon>
                    </template>
                    <template v-slot:append-outer>
                      <v-icon
                        color='primary'
                        @click='courseDiscount++, handleChangeCourse()'
                      >
                        {{ icons.mdiPlus }}
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols='12'>
              <v-card-title class='pl-0 pr-2 text-subtitle-1 font-weight-semibold'>
                <span> {{ $t('total_price') }}</span>
                <v-spacer></v-spacer>
                <u class='error--text me-2'>{{ courseTotalPrice | formatPrice }}</u>
                {{ $t('THB') }}
              </v-card-title>
            </v-col>
            <v-col cols='12'>
              <v-row
                v-if='commissionId'
                align='center'
                justify='center'
              >
                <v-col
                  cols='4'
                  class='font-weight-semibold'
                >
                  {{ $t('commission') }}
                </v-col>
                <v-col cols='8'>
                  <v-select
                    v-model='commissionSelect'
                    :items='teamsAndUserList'
                    item-text='teams_name'
                    :placeholder="$t('staff_team')"
                    item-value='teams_id'
                    dense
                    outlined
                    hide-details
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color='primary'
            :disabled='courseTotalPrice < 0 || addCartLoading'
            :loading='addCartLoading'
            @click='addCourseToCart'
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            outlined
            color='secondary'
            @click='isSelectCourse = false'
          >
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch, onMounted } from '@vue/composition-api'
import {
  mdiCheckCircleOutline, mdiMinus, mdiPlus, mdiTimetable, mdiClockOutline, mdiPill,
} from '@mdi/js'
import courseGroup from '@/api/courseManagement/courseGroup'
import editReceipt from '@/api/receipt/editReceipt'
import footerDataTables from '@/components/basicComponents/footerDataTables-sm.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTables-smEN.json'
import { i18n } from '@/plugins/i18n'
import { formatPrice } from '@/plugins/filters'
import store from '@/store'
import router from '@/router'
import CourseType from '@/components/basicComponents/CourseType.vue'

export default {
  filters: {
    formatPrice,
  },
  components: {
    CourseType,
  },
  props: {
    commissionId: {
      type: Boolean,
      default: false,
    },
    teamsAndUserList: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const dataCourseList = ref([])
    const dataCourseGroupList = ref([])
    const searchtext = ref('')
    const coursegroup_id = ref('')
    const drug_id_pri = ref('')
    const isSelectCourse = ref(false)
    const objCoruseSelect = ref({})
    const commissionSelect = ref('')
    const courseDiscount = ref(0)
    const coursePrice = ref(0)
    const addCartLoading = ref(false)
    const options = ref({})
    const columns = ref([
      { text: i18n.t('code'), value: 'course_id', width: 50 },
      { text: i18n.t('name'), value: 'course_name', width: 200 },
      {
        text: i18n.t('price'), value: 'course_price', width: 80, align: 'end',
      },
      {
        text: i18n.t('select'), value: 'actions', width: 50, align: 'center fixed',
      },
    ])
    const loading = ref(false)
    const courseTotalPrice = ref('')
    const totalDataTableList = ref(0)
    const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
    const { courseGroupList } = courseGroup
    const { editReceiptCourseList, editReceiptAddCertCourse } = editReceipt

    const handleChangeCourse = () => {
      if (!courseDiscount.value) {
        courseDiscount.value = 0
      }
      if (!coursePrice.value) {
        coursePrice.value = 0
      }
      courseTotalPrice.value = coursePrice.value - courseDiscount.value
    }

    courseGroupList({
      searchtext: '',
      coursegroup_status_id: '1',
    }).then(res => {
      dataCourseGroupList.value = res
      dataCourseGroupList.value.unshift({
        coursegroup_id: 'set',
        coursegroup_name: i18n.t('courseSet'),
      })
      dataCourseGroupList.value.unshift({
        coursegroup_id: '',
        coursegroup_name: i18n.t('all_categories'),
      })
      getCourseList()
    })

    const getCourseList = () => {
      loading.value = true
      editReceiptCourseList({
          searchtext: searchtext.value,
          coursegroup_id: coursegroup_id.value,
          active_page: options.value.page,
          lang: i18n.locale,
          per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
        },
        coursegroup_id.value === 'set' ? 'order/editcourseset' : 'order/editcourse',
      ).then(res => {
        const {
          data, count, per_page,
        } = res
        dataCourseList.value = coursegroup_id.value === 'set' ? data.map(item => {
          item.course_id = item.courseset_code
          item.course_name = item.courseset_name
          item.course_price = item.courseset_price
          return item
        }) : data
        totalDataTableList.value = count
        options.value.page = per_page
        loading.value = false
      })
    }

    const selectCourse = value => {
      courseDiscount.value = 0
      objCoruseSelect.value = value
      coursePrice.value = +value.course_price
      handleChangeCourse()
      isSelectCourse.value = true
    }

    const addCourseToCart = () => {
      addCartLoading.value = true
      let body = coursegroup_id.value === 'set' ? {
        order_id_pri: router.currentRoute.params.id,
        courseset_id: objCoruseSelect.value.courseset_id,
        teams_id: props.commissionId ? commissionSelect.value : '',
        lang: i18n.locale,
      } : {
        order_id_pri: router.currentRoute.params.id,
        course_id_pri: objCoruseSelect.value.course_id_pri,
        course_price: coursePrice.value,
        course_discount: courseDiscount.value,
        course_total: courseTotalPrice.value,
        teams_id: props.commissionId ? commissionSelect.value : '',
        lang: i18n.locale,
      }
      editReceiptAddCertCourse(body,
        coursegroup_id.value === 'set' ? 'order/editcartcourseset' : 'order/editcartcourse',
      ).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('onAdd')
        isSelectCourse.value = false
        addCartLoading.value = false
      })
    }

    onMounted(() => {
      commissionSelect.value = props.teamsAndUserList.find(item => item.active_id == 1) ? props.teamsAndUserList.find(item => item.active_id == 1).teams_id : null
    })

    watch(() => props.teamsAndUserList, value => {
      commissionSelect.value = value.find(item => item.active_id == 1) ? value.find(item => item.active_id == 1).teams_id : null
    })

    watch([searchtext, coursegroup_id, options], (newvalue, oldvalue) => {
      if (newvalue[0] !== oldvalue[0] || newvalue[1] !== oldvalue[1]) {
        options.value.page = 1
      }
      getCourseList()
    })

    return {
      dataCourseList,
      dataCourseGroupList,
      searchtext,
      coursegroup_id,
      totalDataTableList,
      drug_id_pri,
      options,
      footer,
      columns,
      loading,
      isSelectCourse,
      objCoruseSelect,
      coursePrice,
      commissionSelect,
      courseTotalPrice,
      addCartLoading,
      courseDiscount,
      getCourseList,
      handleChangeCourse,
      selectCourse,
      addCourseToCart,
      icons: {
        mdiCheckCircleOutline,
        mdiTimetable,
        mdiClockOutline,
        mdiPill,
        mdiMinus,
        mdiPlus,
      },

    }
  },
}
</script>
