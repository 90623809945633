<template>
  <div>
    <v-row
      class='px-2'
      no-gutters
    >
      <v-col
        cols='12'
        lg='5'
      >
        <v-autocomplete
          v-model='checkgroup_id'
          :items='dataCheckGroupList'
          item-text='checkgroup_name'
          item-value='checkgroup_id'
          dense
          hide-details
          class='my-2 mx-1'
          :label="$t('ExaminationCategory')"
          outlined
        ></v-autocomplete>
      </v-col>
      <v-col
        cols='12'
        lg='7'
      >
        <v-text-field
          v-model='searchtext'
          hide-details
          :placeholder="`${$t('code')}, ${$t('name')} ${$t('check')}`"
          class='my-2 mx-1'
          outlined
          dense
          :label="$t('checkListSearch')"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      disable-filtering
      :headers='columns'
      :items='dataCheckList'
      :options.sync='options'
      :server-items-length='totalDataTableList'
      :footer-props='footer'
      :loading='loading'
      mobile-breakpoint='0'
      dense
      height='465'
      disable-sort
      :loading-text="$t('data_loading')"
      :no-data-text="$t('no_information')"
    >
      <template v-slot:[`item.checking_code`]='{ item }'>
        {{ item.checking_code || '-' }}
      </template>
      <template v-slot:[`item.checking_name`]='{ item }'>
        <v-chip
          v-if="checkgroup_id === 'set'"
          color='success'
          :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg success--text`"
          small
        >
          <span>
            {{ $t('set') }}
          </span>
        </v-chip>
        <CheckSetType
          v-else
          :type='item.checking_set_id'
          class='mb-1'
        />
        {{ item.checking_name }}
      </template>
      <template v-slot:[`item.checking_cost`]='{ item }'>
        {{ item.checking_cost| formatPrice }}
      </template>
      <template v-slot:[`item.actions`]='{ item }'>
        <v-tooltip
          top
          color='#212121'
        >
          <template v-slot:activator='{ on, attrs }'>
            <v-btn
              color='primary'
              icon
              v-bind='attrs'
              v-on='on'
              @click='selectChecking(item)'
            >
              <v-icon>{{ icons.mdiCheckCircleOutline }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('select') }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog
      v-model='isSelectCheck'
      persistent
      max-width='400'
    >
      <v-card>
        <v-card-title class='text-subtitle-1'>
          <span>
             <v-chip
               v-if="checkgroup_id === 'set'"
               color='success'
               :class="`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg success--text`"
               small
             >
            <span>
            {{ $t('checkingSet') }}
            </span>
          </v-chip>
            <CheckSetType
              v-else
              :type='objCheckingSelect.checking_set_id'
              class='mb-1'
            />
            ({{ objCheckingSelect.checking_code || '-' }})
            {{ objCheckingSelect.checking_name }}
            <span class='primary--text'>
              {{ $t('price') }}
              <span class='error--text'>{{
                  objCheckingSelect.checking_cost
                }}</span>
              {{ $t('THB') }}
            </span>
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols='12'>
              <v-row
                align='center'
                justify='center'
              >
                <v-col cols='6'>
                  <span class='font-weight-semibold'>
                    {{ $t('a_price_unit') }}
                  </span>
                </v-col>
                <v-col cols='6'>
                  <v-text-field
                    v-model='checkingPrice'
                    outlined
                    hide-details
                    reverse
                    dense
                    disabled
                    type='number'
                    @input='handleChangeChecking'
                  >
                    <template v-slot:prepend>
                      <v-icon
                        color='primary'
                        @click='
                          checkingPrice > 0
                            ? checkingPrice--
                            : (checkingPrice = 0),
                          handleChangeChecking()
                        '
                      >
                        {{ icons.mdiMinus }}
                      </v-icon>
                    </template>
                    <template v-slot:append-outer>
                      <v-icon
                        color='primary'
                        @click='checkingPrice++, handleChangeChecking()'
                      >
                        {{ icons.mdiPlus }}
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row
                align='center'
                justify='center'
              >
                <v-col cols='6'>
                  <span class='font-weight-semibold'>
                    {{ $t('discount_unit') }}
                  </span>
                </v-col>
                <v-col cols='6'>
                  <v-text-field
                    v-model='checkingDiscount'
                    outlined
                    hide-details
                    :disabled="checkgroup_id === 'set'"
                    reverse
                    dense
                    type='number'
                    @input='handleChangeChecking'
                  >
                    <template v-slot:prepend>
                      <v-icon
                        color='primary'
                        @click='
                          checkingDiscount > 0
                            ? checkingDiscount--
                            : (checkingDiscount = 0),
                          handleChangeChecking()
                        '
                      >
                        {{ icons.mdiMinus }}
                      </v-icon>
                    </template>
                    <template v-slot:append-outer>
                      <v-icon
                        color='primary'
                        @click='checkingDiscount++, handleChangeChecking()'
                      >
                        {{ icons.mdiPlus }}
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols='12'>
              <v-card-title class='pl-0 pr-2 text-subtitle-1 font-weight-semibold'>
                <span> {{ $t('total_price') }}</span>
                <v-spacer></v-spacer>
                <u class='error--text me-2'>{{ checkingTotalPrice | formatPrice }}</u>
                {{ $t('THB') }}
              </v-card-title>
            </v-col>
            <v-col cols='12'>
              <v-row
                v-if='commissionId'
                align='center'
                justify='center'
              >
                <v-col
                  cols='4'
                  class='font-weight-semibold'
                >
                  {{ $t('commission') }}
                </v-col>
                <v-col cols='8'>
                  <v-select
                    v-model='commissionSelect'
                    :items='teamsAndUserList'
                    item-text='teams_name'
                    :placeholder="$t('staff_team')"
                    item-value='teams_id'
                    dense
                    outlined
                    hide-details
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color='primary'
            :disabled='checkingTotalPrice < 0 || addCartLoading'
            :loading='addCartLoading'
            @click='addCheckToCart'
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            outlined
            color='secondary'
            @click='isSelectCheck = false'
          >
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch, onMounted } from '@vue/composition-api'
import { mdiCheckCircleOutline, mdiMinus, mdiPlus } from '@mdi/js'
import checkGroup from '@/api/examination/checkGroup'
import editReceipt from '@/api/receipt/editReceipt'
import footerDataTables from '@/components/basicComponents/footerDataTables-sm.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTables-smEN.json'
import { i18n } from '@/plugins/i18n'
import { formatPrice } from '@/plugins/filters'
import router from '@/router'
import store from '@/store'
import CheckSetType from '@/components/basicComponents/CheckSetType.vue'

export default {
  filters: {
    formatPrice,
  },
  components: {
    CheckSetType,
  },
  props: {
    commissionId: {
      type: Boolean,
      default: false,
    },
    teamsAndUserList: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const dataCheckList = ref([])
    const dataCheckGroupList = ref([])
    const searchtext = ref('')
    const checkgroup_id = ref('')
    const drug_id_pri = ref('')
    const isSelectCheck = ref(false)
    const options = ref({})
    const columns = ref([
      { text: i18n.t('code'), value: 'checking_code', width: 50 },
      { text: i18n.t('name'), value: 'checking_name', width: 200 },
      {
        text: i18n.t('price'), value: 'checking_cost', width: 80, align: 'end',
      },
      {
        text: i18n.t('select'), value: 'actions', width: 50, align: 'center fixed',
      },
    ])
    const loading = ref(false)
    const addCartLoading = ref(false)
    const totalDataTableList = ref(0)
    const objCheckingSelect = ref({})
    const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
    const checkingDiscount = ref(0)
    const checkingPrice = ref(0)
    const checkingTotalPrice = ref(0)
    const commissionSelect = ref('')
    const { checkGroupList } = checkGroup
    const { editReceiptCheckList, editReceiptAddCertChecking } = editReceipt

    checkGroupList({
      searchtext: '',
      checkgroup_status_id: '1',
    }).then(res => {
      dataCheckGroupList.value = res
      dataCheckGroupList.value.unshift({
        checkgroup_id: 'set',
        checkgroup_name: i18n.t('checkingSet'),
      })
      dataCheckGroupList.value.unshift({
        checkgroup_id: '',
        checkgroup_name: i18n.t('all_categories'),
      })
      getCheckList()
    })

    const handleChangeChecking = () => {
      if (!checkingDiscount.value) {
        checkingDiscount.value = 0
      }
      if (!checkingPrice.value) {
        checkingPrice.value = 0
      }
      checkingTotalPrice.value = checkingPrice.value - checkingDiscount.value
    }

    const selectChecking = item => {
      objCheckingSelect.value = item
      checkingPrice.value = item.checking_cost
      checkingDiscount.value = 0
      handleChangeChecking()
      isSelectCheck.value = true
    }

    const getCheckList = () => {
      loading.value = true
      editReceiptCheckList({
          searchtext: searchtext.value,
          checkgroup_id: checkgroup_id.value,
          active_page: options.value.page,
          lang: i18n.locale,
          per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
        },
        checkgroup_id.value === 'set' ? 'order/editcheckingset' : 'order/editchecking',
      ).then(res => {
        const {
          data, count, per_page,
        } = res
        dataCheckList.value = checkgroup_id.value === 'set' ? data.map(item => {
          item.checking_code = item.checkingset_code
          item.checking_name = item.checkingset_name
          item.checking_cost = item.checkingset_cost
          return item
        }) : data
        totalDataTableList.value = count
        options.value.page = per_page
        loading.value = false
      })
    }

    const addCheckToCart = () => {
      addCartLoading.value = true
      let body = checkgroup_id.value === 'set' ? {
        order_id_pri: router.currentRoute.params.id,
        checkingset_id: objCheckingSelect.value.checkingset_id,
        teams_id: props.commissionId ? commissionSelect.value : '',
        lang: i18n.locale,
      } : {
        order_id_pri: router.currentRoute.params.id,
        checking_id: objCheckingSelect.value.checking_id,
        checking_price: checkingPrice.value,
        checking_discount: checkingDiscount.value,
        checking_total: checkingTotalPrice.value,
        teams_id: props.commissionId ? commissionSelect.value : '',
        lang: i18n.locale,
      }
      editReceiptAddCertChecking(body,
        checkgroup_id.value === 'set' ? 'order/editcartcheckingset' : 'order/editcartchecking',
      ).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('onAdd')
        isSelectCheck.value = false
        addCartLoading.value = false
      })
    }

    onMounted(() => {
      commissionSelect.value = props.teamsAndUserList.find(item => item.active_id == 1) ? props.teamsAndUserList.find(item => item.active_id == 1).teams_id : null
    })

    watch(() => props.teamsAndUserList, value => {
      commissionSelect.value = value.find(item => item.active_id == 1) ? value.find(item => item.active_id == 1).teams_id : null
    })
    watch([searchtext, checkgroup_id, options], (newvalue, oldvalue) => {
      if (newvalue[0] !== oldvalue[0] || newvalue[1] !== oldvalue[1]) {
        options.value.page = 1
      }
      getCheckList()
    })

    return {
      dataCheckList,
      dataCheckGroupList,
      searchtext,
      checkgroup_id,
      totalDataTableList,
      drug_id_pri,
      options,
      footer,
      columns,
      loading,
      objCheckingSelect,
      isSelectCheck,
      checkingPrice,
      checkingDiscount,
      checkingTotalPrice,
      commissionSelect,
      addCartLoading,
      addCheckToCart,
      getCheckList,
      selectChecking,
      handleChangeChecking,

      icons: {
        mdiCheckCircleOutline,
        mdiMinus,
        mdiPlus,
      },

    }
  },
}
</script>
