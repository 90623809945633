import { i18n } from '@/plugins/i18n'
import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

// PHP api
const editReceiptAddListToCart = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'order/edit',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('add list edit to cart  err : ', err)

      return {}
    })

  return response
}

// PHP api
const editReceiptGet = async id => {
  //await refreshToken()
  const response = await api
    .get({
      path: 'order/edit',
      param: `${id}/${i18n.locale}`,
    })
    .then(res => res.data || {})
    .catch(err => {
      console.error('get order edit  err : ', err)

      return {}
    })

  return response
}

const editReceiptCourseList = async (body, path) => {
  //await refreshToken()
  const response = await api
    .post({
      path: path ? path : 'order/editcourse',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('get course list in receipt err : ', err)

      return {}
    })

  return response
}

const editReceiptDrugList = async (body, path) => {
  //await refreshToken()
  const response = await api
    .post({
      path: path ? path : 'order/editdrug',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('get drug list in receipt err : ', err)

      return {}
    })

  return response
}

const editReceiptCheckList = async (body, path) => {
  //await refreshToken()
  const response = await api
    .post({
      path: path ? path : 'order/editchecking',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('error : ', err)

      return {}
    })

  return response
}

// PHP api
const editReceiptAddCertDrug = async (body, path) => {
  //await refreshToken()
  const response = await api
    .post({
      path: path ? path : 'order/editcartdrug',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('add drug to cart list in edit receipt err : ', err)

      return {}
    })

  return response
}

// PHP api
const editReceiptAddCertMember = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'order/editcartmember',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('add member to cart list in edit receipt err : ', err)

      return {}
    })

  return response
}

// PHP api
const editReceiptAddCertCourse = async (body, path) => {
  //await refreshToken()
  const response = await api
    .post({
      path: path ? path : 'order/editcartcourse',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('add course to cart list in edit receipt err : ', err)

      return {}
    })

  return response
}

// PHP api
const editReceiptAddCertChecking = async (body, path) => {
  //await refreshToken()
  const response = await api
    .post({
      path: path ? path : 'order/editcartchecking',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('error : ', err)
      return {}
    })

  return response
}

const editReceiptRemoveCart = async id => {
  //await refreshToken()
  const response = await api
    .delete({
      path: 'order/editcart',
      param: id,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('remove drug from cart : ', err)

      return {}
    })

  return response
}

// PHP api
const editReceiptSave = async body => {
  //await refreshToken()
  const response = await api
    .put({
      path: 'order/v2',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('save edit receipt err : ', err)

      return {}
    })

  return response
}

export default {
  editReceiptGet,
  editReceiptCourseList,
  editReceiptRemoveCart,
  editReceiptDrugList,
  editReceiptAddCertDrug,
  editReceiptAddCertCourse,
  editReceiptSave,
  editReceiptAddListToCart,
  editReceiptAddCertMember,
  editReceiptCheckList,
  editReceiptAddCertChecking,
}
