<template>
  <div>
    <v-row class="px-2" no-gutters>
      <v-col cols="12" lg="5">
        <v-autocomplete
          v-model="memberTypeSelect"
          :items="dataMemberTypeList"
          item-text="name"
          item-value="id"
          dense
          hide-details
          class="my-2 mx-1"
          :label="$t('ExaminationCategory')"
          outlined
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" lg="7">
        <v-text-field
          v-model="searchtext"
          hide-details
          :placeholder="`${$t('code')}, ${$t('name')} ${$t('check')}`"
          class="my-2 mx-1"
          outlined
          dense
          :label="$t('checkListSearch')"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      disable-filtering
      :headers="columns"
      :items="dataMemberList"
      :options.sync="options"
      :server-items-length="totalDataTableList"
      :footer-props="footer"
      :loading="loading"
      mobile-breakpoint="0"
      dense
      height="465"
      disable-sort
      :loading-text="$t('data_loading')"
      :no-data-text="$t('no_information')"
    >
      <template v-slot:[`item.price`]="{ item }">
        {{ item.price | formatPrice }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn color="primary" icon @click="selectMember(item)">
          <v-icon>{{ icons.mdiCheckCircleOutline }}</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="isSelectMember" persistent max-width="400">
      <v-card>
        <v-card-title class="text-subtitle-1">
          <span>
            {{ objMemberSelect.name }}
            <span class="primary--text">
              {{ $t('price') }}
              <span class="error--text">{{ objMemberSelect.price }}</span>
              {{ $t('THB') }}
            </span>
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12">
              <v-row align="center" justify="center">
                <v-col cols="6">
                  <span class="font-weight-semibold">
                    {{ $t('a_price_unit') }}
                  </span>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="memberPrice"
                    outlined
                    hide-details
                    reverse
                    dense
                    type="number"
                    @input="handleChangeMember"
                  >
                    <template v-slot:prepend>
                      <v-icon
                        color="primary"
                        @click="memberPrice > 0 ? memberPrice-- : (memberPrice = 0), handleChangeMember()"
                      >
                        {{ icons.mdiMinus }}
                      </v-icon>
                    </template>
                    <template v-slot:append-outer>
                      <v-icon color="primary" @click="memberPrice++, handleChangeMember()">
                        {{ icons.mdiPlus }}
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col cols="6">
                  <span class="font-weight-semibold">
                    {{ $t('amount') }}
                  </span>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="memberAmount"
                    outlined
                    reverse
                    hide-details
                    dense
                    type="number"
                    @input="handleChangeMember"
                  >
                    <template v-slot:prepend>
                      <v-icon
                        color="primary"
                        @click="memberAmount > 1 ? memberAmount-- : (memberAmount = 1), handleChangeMember()"
                      >
                        {{ icons.mdiMinus }}
                      </v-icon>
                    </template>
                    <template v-slot:append-outer>
                      <v-icon color="primary" @click="memberAmount++, handleChangeMember()">
                        {{ icons.mdiPlus }}
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col cols="6">
                  <span class="font-weight-semibold">
                    {{ $t('discount_unit') }}
                  </span>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="memberDiscount"
                    outlined
                    hide-details
                    reverse
                    dense
                    type="number"
                    @input="handleChangeMember"
                  >
                    <template v-slot:prepend>
                      <v-icon
                        color="primary"
                        @click="memberDiscount > 0 ? memberDiscount-- : (memberDiscount = 0), handleChangeMember()"
                      >
                        {{ icons.mdiMinus }}
                      </v-icon>
                    </template>
                    <template v-slot:append-outer>
                      <v-icon color="primary" @click="memberDiscount++, handleChangeMember()">
                        {{ icons.mdiPlus }}
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-card-title class="pl-0 pr-2 text-subtitle-1 font-weight-semibold">
                <span> {{ $t('total_price') }}</span> <v-spacer></v-spacer>
                <u class="error--text me-2">{{ memberPriceTotal | formatPrice }}</u>
                {{ $t('THB') }}
              </v-card-title>
            </v-col>
            <v-col cols="12">
              <v-row v-if="commissionId" align="center" justify="center">
                <v-col cols="4" class="font-weight-semibold">
                  {{ $t('commission') }}
                </v-col>
                <v-col cols="8">
                  <v-select
                    v-model="commissionSelect"
                    :items="teamsAndUserList"
                    item-text="teams_name"
                    :placeholder="$t('staff_team')"
                    item-value="teams_id"
                    dense
                    outlined
                    hide-details
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :disabled="memberPriceTotal < 0 || addCartLoading"
            :loading="addCartLoading"
            @click="addMemberToCart"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn outlined color="secondary" @click="isSelectMember = false">
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch, onMounted } from '@vue/composition-api'
import { mdiCheckCircleOutline, mdiMinus, mdiPlus } from '@mdi/js'
import createReceipt from '@/api/receipt/createReceipt'
import footerDataTables from '@/components/basicComponents/footerDataTables-sm.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTables-smEN.json'
import { i18n } from '@/plugins/i18n'
import { formatPrice } from '@/plugins/filters'
import router from '@/router'
import store from '@/store'
import memberType from '@/api/member/memberType'
import editReceipt from '@/api/receipt/editReceipt'

export default {
  filters: {
    formatPrice,
  },

  props: {
    commissionId: {
      type: Boolean,
      default: false,
    },
    teamsAndUserList: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const searchtext = ref('')
    const isSelectMember = ref(false)
    const options = ref({})
    const columns = ref([
      { text: i18n.t('name'), value: 'name', width: 200 },
      {
        text: i18n.t('price'),
        value: 'price',
        width: 80,
        align: 'end',
      },
      {
        text: i18n.t('select'),
        value: 'actions',
        width: 50,
        align: 'center fixed',
      },
    ])
    const loading = ref(false)
    const addCartLoading = ref(false)
    const totalDataTableList = ref(0)
    const objMemberSelect = ref({})
    const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
    const memberDiscount = ref(0)
    const memberPrice = ref(0)
    const memberPriceTotal = ref(0)
    const commissionSelect = ref('')
    const memberAmount = ref(0)
    const dataMemberTypeList = ref([])
    const memberTypeSelect = ref('')
    const dataMemberList = ref([])

    const { memberTypeList } = memberType

    const { receiptMemberList } = createReceipt
    const { editReceiptAddCertMember } = editReceipt

    memberTypeList({
      searchtext: '',
      memberTypeStatus: '1',
    }).then(res => {
      dataMemberTypeList.value = res
      dataMemberTypeList.value.unshift({
        id: '',
        name: i18n.t('all_categories'),
      })
      getMemberList()
    })

    const getMemberList = () => {
      loading.value = true
      receiptMemberList({
        searchtext: searchtext.value,
        member_type_id: memberTypeSelect.value,
        active_page: options.value.page,
        status: '1',
        per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
      }).then(res => {
        const { data, count, per_page } = res
        dataMemberList.value = data
        totalDataTableList.value = count
        options.value.page = per_page
        loading.value = false
      })
    }

    const handleChangeMember = () => {
      if (!memberDiscount.value) {
        memberDiscount.value = 0
      }
      if (!memberPrice.value) {
        memberPrice.value = 0
      }

      const pricePerUnit = +memberPrice.value * +memberAmount.value
      const discountPerUnit = +pricePerUnit - +memberAmount.value * +memberDiscount.value
      memberPriceTotal.value = discountPerUnit
    }

    const selectMember = item => {
      objMemberSelect.value = item
      memberPrice.value = item.price
      memberDiscount.value = 0
      memberAmount.value = 1
      handleChangeMember()
      isSelectMember.value = true
    }

    const addMemberToCart = () => {
      addCartLoading.value = true
      editReceiptAddCertMember({
        order_id_pri: router.currentRoute.params.id,
        member_id_pri: objMemberSelect.value.id,
        member_amount: memberAmount.value,
        member_discount: memberDiscount.value,
        member_price: memberPrice.value,
        teams_id: props.commissionId ? commissionSelect.value : '',
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('onAdd')
        isSelectMember.value = false
        addCartLoading.value = false
      })
    }
    onMounted(() => {
      commissionSelect.value = props.teamsAndUserList.find(item => item.active_id == 1)
        ? props.teamsAndUserList.find(item => item.active_id == 1).teams_id
        : null
    })

    watch(
      () => props.teamsAndUserList,
      value => {
        commissionSelect.value = value.find(item => item.active_id == 1)
          ? value.find(item => item.active_id == 1).teams_id
          : null
      },
    )

    watch([searchtext, memberTypeSelect, options], (newvalue, oldvalue) => {
      if (newvalue[0] !== oldvalue[0] || newvalue[1] !== oldvalue[1]) {
        options.value.page = 1
      }
      getMemberList()
    })

    return {
      searchtext,
      totalDataTableList,
      options,
      footer,
      columns,
      loading,
      objMemberSelect,
      isSelectMember,
      memberPrice,
      memberDiscount,
      memberPriceTotal,
      commissionSelect,
      addCartLoading,
      dataMemberTypeList,
      memberTypeSelect,
      dataMemberList,
      memberAmount,
      addMemberToCart,
      selectMember,
      handleChangeMember,

      icons: {
        mdiCheckCircleOutline,
        mdiMinus,
        mdiPlus,
      },
    }
  },
}
</script>
