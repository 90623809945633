<template>
  <div id='user-view'>
    <v-row>
      <v-col cols='12' lg='5'>
        <v-card :loading='leftBlockLoading'>
          <v-card-actions class='py-2 pl-2 pr-0'>
            <div v-if='leftBlockLoading' class='py-2' align='center'>
              {{ $t('data_loading') }}
            </div>
            <v-chip
              v-if='!leftBlockLoading'
              color='error'
              label
              :class="`v-chip-${$vuetify.theme.dark ? 'dark' : 'light'}-bg error--text`"
            >
              {{ orderId }}
            </v-chip>
            <v-spacer></v-spacer>
            <PopupCustomer v-if='!leftBlockLoading' class='pt-1' :customer-data='customerData' />
          </v-card-actions>
          <v-divider></v-divider>
          <v-tabs v-model='tab' class='user-tabs' grow centered show-arrows>
            <v-tab>
              <v-icon size='20' class='me-1'>
                {{ icons.mdiPill }}
              </v-icon>
              <span>{{ $t('drug_equipment') }}</span>
            </v-tab>
            <v-tab>
              <v-icon size='20' class='me-1'>
                {{ icons.mdiAutoFix }}
              </v-icon>
              <span>{{ $t('course') }}</span>
            </v-tab>
            <v-tab>
              <v-icon size='20' class='me-1'>
                {{ icons.mdiStethoscope }}
              </v-icon>
              <span>{{ $t('check') }}</span>
            </v-tab>
            <v-tab>
              <v-icon size='20' class='me-1'>
                {{ icons.mdiCardAccountDetailsStarOutline }}
              </v-icon>
              <span>{{ $t('memberCard') }}</span>
            </v-tab>
          </v-tabs>
          <!-- tabs item -->
          <v-tabs-items id='user-tabs-content' v-model='tab'>
            <v-tab-item>
              <EditDrugList
                :commission-id='commissionStatus'
                :teams-and-user-list='teamsAndUserList'
                :status-delete='statusDelete'
                :customerDrugList='customerDrugList'
                @onAdd='getReceiptEdit'
              />
            </v-tab-item>
            <v-tab-item>
              <EditCourseList
                :commission-id='commissionStatus'
                :teams-and-user-list='teamsAndUserList'
                @onAdd='getReceiptEdit'
              />
            </v-tab-item>
            <v-tab-item>
              <EditCheckList
                :commission-id='commissionStatus'
                :teams-and-user-list='teamsAndUserList'
                @onAdd='getReceiptEdit'
              />
            </v-tab-item>
            <v-tab-item>
              <EditMembetList
                :commission-id='commissionStatus'
                :teams-and-user-list='teamsAndUserList'
                @onAdd='getReceiptEdit'
              />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
      <v-col cols='12' lg='7'>
        <v-card>
          <v-card-actions class='pt-2 pb-2 px-2'>
            <v-dialog ref='datePicker' v-model.trim='isShowDate' :return-value.sync='date_create'
                      width='290px'>
              <template v-slot:activator='{ on, attrs }'>
                <v-text-field
                  v-model.trim='date_create'
                  :label="$t('date')"
                  v-bind='attrs'
                  :prepend-inner-icon='icons.mdiCalendar'
                  outlined
                  dense
                  hide-details
                  style='max-width: 250px'
                  v-on='on'
                ></v-text-field>
              </template>
              <v-date-picker v-model.trim='date_create' class='set-font' :locale='$i18n.locale'
                             scrollable>
                <v-spacer></v-spacer>
                <v-btn text color='primary' @click='$refs.datePicker.save(date_create)'>
                  {{ $t('confirm') }}
                </v-btn>
                <v-btn text color='secondary' @click='isShowDate = false'>
                  {{ $t('cancel') }}
                </v-btn>

                <v-spacer></v-spacer>
              </v-date-picker>
            </v-dialog>
            <v-spacer></v-spacer>
          </v-card-actions>
          <v-data-table
            :headers='columns'
            :items='cartList || []'
            :options.sync='options'
            :loading='cartLoading'
            mobile-breakpoint='0'
            dense
            fixed-header
            height='300'
            disable-sort
            hide-default-footer
            :loading-text="$t('data_loading')"
            :no-data-text="$t('no_information')"
          >
            <template v-slot:[`item.id`]='{ index }'>
              {{ index + 1 }}
            </template>
            <template v-slot:[`item.orderdetail_edit_name`]='{ item }'>
              <div v-if='item.orderdetail_edit_name_set'>
                <v-chip
                  :color='item.drugset_id?`primary`:item.checkingset_id?`success`:item.couresset_id?`info`:``'
                  :class=" item.drugset_id?`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg primary--text`:item.checkingset_id?`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg success--text`:item.courseset_id?`v-chip-${$vuetify.theme.dark?'dark': 'light'}-bg info--text`:``"
                  small
                >
                  <span>
                    {{ item.drugset_id ? $t('setDrug') : item.checkingset_id ? $t('checkingSet') : item.courseset_id ? $t('courseSet') : ``
                    }}
                  </span>
                </v-chip>
                {{ item.orderdetail_edit_name }}
              </div>
              <div v-else>
                {{ item.orderdetail_edit_name }}
              </div>
            </template>
            <template v-slot:[`item.orderdetail_temp_price`]='{ item }'>
              {{ item.checking_id ? item.orderdetail_edit_cost : item.orderdetail_edit_price }}
            </template>

            <template v-slot:[`item.orderdetail_edit_discount`]='{ item }'>
              {{ item.orderdetail_edit_discount | formatPrice }}
            </template>

            <template v-slot:[`item.orderdetail_edit_total`]='{ item }'>
              {{ item.orderdetail_edit_total | formatPrice }}
            </template>

            <template v-slot:[`item.actions`]='{ item }'>
              <v-tooltip color='#212121' top class='p-0 m-0'>
                <template v-slot:activator='{ on, attrs }'>
                  <v-btn
                    icon
                    color='error'
                    v-bind='attrs'
                    :loading='removeLoading'
                    :disabled='removeLoading'
                    small
                    @click='removeCart(item.orderdetail_edit_id_pri)'
                    v-on='on'
                    v-if='checkDisplay(item.orderdetail_edit_type_id, cartData.opd_id, item.opdchecking_id)'
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('delete') }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
        <v-card :loading='cartLoading || sumLoading' class='mt-6' min-height='304'>
          <v-row class='px-2'>
            <v-col cols='12' md='6'>
              <v-row align='center' justify='center'>
                <v-col cols='6'>
                  <span class='font-weight-semibold'> {{ $t('total_price') }}</span>
                </v-col>
                <v-col cols='6' class='text-right'> {{ totalPrice | formatPrice }} {{ currentCurrency == 2 ? $t('USD') : $t('THB') }}
                </v-col>
              </v-row>
              <v-row align='center' justify='center'>
                <v-col cols='4'>
                  <span class='font-weight-semibold'> {{ $t('discount') }} </span>
                </v-col>
                <v-col cols='8'>
                  <div class='d-flex justify-content-end'>
                    <v-text-field
                      v-model='discount'
                      dense
                      type='number'
                      style='width: 90px'
                      hide-details
                      outlined
                      @input='calculateDiscount'
                    >
                      <template v-slot:label>
                        <span class='error--text'>{{ $t('max_reduction') }} {{ limitDisCount
                          }} %</span>
                      </template>
                    </v-text-field>
                    <v-select
                      v-model='discountType'
                      dense
                      hide-details
                      :label="$t('discount_type')"
                      style='width: 80px'
                      outlined
                      item-text='label'
                      item-value='value'
                      :items='discountTypeList'
                      @input='discountTypeChenage'
                    ></v-select>
                  </div>
                </v-col>
              </v-row>
              <v-row align='center' justify='center'
                     v-if='couponSelect.coupon_id || !listCoupon.length'>
                <v-col cols='6' class='d-flex align-center' style='white-space: nowrap'>
                  <span class='font-weight-semibold me-2'> {{ $t('cashbackValue') }}</span>
                  <v-img src='@/assets/images/transaction.svg' style='cursor: pointer'
                         @click='isShowCashbackList = true'
                         class='me-2'
                         contain max-width='30'></v-img>

                </v-col>
                <v-col cols='6' class='text-right'>
                  <span>
                    {{ coupon_amount }} {{ currentCurrency == 2 ? $t('USD') : $t('THB') }}
                  </span>
                </v-col>
              </v-row>
              <v-row align='center' justify='center'>
                <v-col cols='6'>
                  <span class='font-weight-semibold'> {{ $t('after_discount') }}</span>
                </v-col>
                <v-col cols='6' class='text-right'> {{ totalAfterDiscount | formatPrice }}
                  {{ currentCurrency == 2 ? $t('USD') : $t('THB') }}
                </v-col>
              </v-row>
              <v-row align='center' justify='center'>
                <v-col cols='4'>
                  <span class='font-weight-semibold'> {{ $t('tax_type') }}</span>
                </v-col>
                <v-col cols='8' class='text-right'>
                  <v-select
                    v-model='taxType'
                    dense
                    outlined
                    :label="$t('selectVatType')"
                    :placeholder="$t('selectVatType')"
                    hide-details
                    :items='taxTypeList'
                    item-value='value'
                    :item-text='$i18n.locale'
                    @change='calculateTax'
                  ></v-select>
                </v-col>
              </v-row>
              <v-row align='center' justify='center'>
                <v-col cols='6'>
                  <span class='font-weight-semibold'> {{ $t('vat') }}</span>
                </v-col>
                <v-col cols='6' class='text-right'> {{ vat | formatPrice }} {{ currentCurrency == 2 ? $t('USD') : $t('THB') }}</v-col>
              </v-row>
            </v-col>
            <v-divider vertical class='d-none d-md-inline'></v-divider>
            <v-col cols='12' md='6'>
              <v-row align='center' justify='center'>
                <v-col cols='6'>
                  <span class='font-weight-semibold'>{{ $t('total_value') }}</span>
                </v-col>
                <v-col cols='6' class='text-right'> {{ totalValue | formatPrice }} {{ currentCurrency == 2 ? $t('USD') : $t('THB') }}
                </v-col>
              </v-row>
              <v-row align='center' justify='center'>
                <v-col cols='6'>
                  <u>
                    <span class='font-weight-semibold'> {{ $t('total_paid') }}</span>
                  </u>
                </v-col>
                <v-col cols='6' class='text-right primary--text'>
                  <u> {{ totalPay | formatPrice }} {{ currentCurrency == 2 ? $t('USD') : $t('THB') }}</u>
                </v-col>
              </v-row>
              <v-row no-gutters class='mt-2'>
                <v-col cols='12' class='my-2'>
                  <v-btn color='primary' block :loading='processing' :disabled='processing'
                         @click='saveReceipt'>
                    {{ $t('save') }}
                  </v-btn>
                </v-col>

                <v-col cols='12' class='my-2'>
                  <v-btn
                    color='secondary'
                    outlined
                    :to="{
                      name: 'order-detail',
                      params: { id: $router.currentRoute.params.id },
                    }"
                    block
                  >
                    {{ $t('cancel') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <CashbackList v-model='isShowCashbackList' :cashback-list='listCoupon'
                  status-c='edit'
                  @selectionCoupon='updateCashbackSelection'
                  @cancelCashback='cancelCashback' />
  </div>
</template>

<script>
import {
  mdiPill,
  mdiPlus,
  mdiMinus,
  mdiAutoFix,
  mdiStethoscope,
  mdiCardAccountDetailsStarOutline,
  mdiCalendar,
  mdiPencilOutline,
  mdiDeleteOutline,
} from '@mdi/js'
import useEditReceipt from './useEditReceipt'
import PopupCustomer from '@/components/basicComponents/PopupCustomer.vue'
import EditDrugList from './EditDrugList.vue'
import EditCourseList from './EditCourseList.vue'
import { formatPrice } from '@/plugins/filters'
import EditMembetList from './EditMembetList.vue'
import EditCheckList from './EditCheckList.vue'
import CashbackList from '@/views/receipts/CashbackList'

export default {
  components: {
    CashbackList,
    PopupCustomer,
    EditDrugList,
    EditCourseList,
    EditMembetList,
    EditCheckList,
  },
  filters: {
    formatPrice,
  },

  setup() {
    return {
      ...useEditReceipt(),
      icons: {
        mdiPill,
        mdiPlus,
        mdiMinus,
        mdiAutoFix,
        mdiStethoscope,
        mdiCardAccountDetailsStarOutline,
        mdiCalendar,
        mdiPencilOutline,
        mdiDeleteOutline,
      },
    }
  },
}
</script>

<style lang='scss'>
@import '@core/preset/preset/apps/user.scss';
</style>
