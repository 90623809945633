import vatData from '@/@fake-db/data/vatData.json'
import customer from '@/api/customer/customer'
import profile from '@/api/profile'
import editReceipt from '@/api/receipt/editReceipt'
import document from '@/api/systemSetting/document'
import limitDiscount from '@/api/systemSetting/limitDiscount'
import teamList from '@/api/teamList'
import { i18n } from '@/plugins/i18n'
import router from '@/router'
import store from '@/store'
import receipt from '@/api/receipt/createReceipt'
import { onMounted, ref, watch } from '@vue/composition-api'

export default function useEditReceipt() {
  const moment = require('moment')
  const loading = ref(false)
  const tab = ref(null)
  const customerData = ref({})
  const cartData = ref({})
  const orderId = ref('')
  const leftBlockLoading = ref(true)
  const date_create = ref(moment(new Date()).format('YYYY-MM-DD'))
  const isShowDate = ref(false)
  const cartLoading = ref(false)
  const sumLoading = ref(false)
  const taxTypeList = ref(vatData.data)
  // data Receipt details
  const cartList = ref([])
  const totalPrice = ref(0)
  const discount = ref('0')
  const discountType = ref('0')
  const totalAfterDiscount = ref(0)
  const taxType = ref(0)
  const vat = ref(0)
  const totalValue = ref(0)
  const totalPay = ref(0)
  const limitDisCount = ref(0)
  const calculateLimitDisCount = ref(0)
  const commissionStatus = ref(false)
  const teamsAndUserList = ref([])
  const removeLoading = ref(false)
  const isEditCartDrug = ref(false)
  const statusDelete = ref(false)
  const processingDeposit = ref(false)
  const customerDrugList = ref([])
  const dataEdit = ref({})
  const discountTypeList = ref([
    {
      label: '%',
      value: 1,
    },
    {
      label: i18n.t('bath'),
      value: 2,
    },
  ])
  const currentCurrency = ref('')

  // cart data for
  // { text: i18n.t('code'), value: 'orderdetail_edit_id', width: 100 },
  const options = ref({})
  const columns = ref([
    { text: '#', value: 'id', width: 30 },
    { text: `${i18n.t('list')}/${i18n.t('service')}`, value: 'orderdetail_edit_name', width: 200 },
    {
      text: i18n.t('amount'),
      value: 'orderdetail_edit_amount',
      align: 'end',
      width: 80,
    },
    {
      text: `${i18n.t('price')}/${i18n.t('unit')}`,
      value: 'orderdetail_temp_price',
      align: 'end',
      width: 100,
    },
    {
      text: `${i18n.t('discount')}/${i18n.t('unit')}`,
      value: 'orderdetail_edit_discount',
      align: 'end',
      width: 110,
    },
    {
      text: i18n.t('total_price'),
      value: 'orderdetail_edit_total',
      align: 'end',
      width: 100,
    },
    {
      text: i18n.t('option'),
      value: 'actions',
      align: 'end fixed',
      width: 95,
    },
  ])

  // for payment
  const channelPayList = ref([])
  const order_comment = ref('')
  const depositAmount = ref(0)
  const commissionSelect = ref('')
  const channelPaySelect = ref('')
  const payAmount = ref(0)
  const statusPoint = ref(false)
  const typePayment = ref('1')
  const orderPointsave = ref(0)
  const isPayment = ref(false)
  const isDeposit = ref(false)
  const processing = ref(false)
  const isShowCashbackList = ref(false)
  const cashbackSelect = ref('')

  const listCoupon = ref([])
  const couponSelect = ref({})
  const coupon_amount = ref('0.00')

  const { customerById } = customer
  const { teamListGet } = teamList
  const { documentSetting } = document

  const {
    editReceiptAddListToCart,
    editReceiptGet,
    editReceiptRemoveCart,
    editReceiptSave,
  } = editReceipt
  const { limitDiscountGet } = limitDiscount


  const getCoupon = () => {
    receipt.getCouponList({
      customer_id_pri: customerData.value.customer_id_pri,
      coupon_status_id: 1,
      active_page: 1,
      per_page: 0,
      lang: i18n.locale,
    }).then(({ data }) => {
      listCoupon.value = data
    })
  }

  const cancelCashback = () => {
    coupon_amount.value = '0.00'
    couponSelect.value = {}
    isShowCashbackList.value = false
  }

  // ดึงข้อมูลผู้ใช้งาน
  profile().then(res => {
    getLimitDiscount(res.role_id)
  })

  // ดึงข้อมูลทีมและผู้ใช้งานเอามาให้เลือกค่าคอมมิชชั่น
  const getTeamList = () => {
    teamListGet().then(res => {
      teamsAndUserList.value = res
      commissionSelect.value = res.find(item => item.active_id == 1).teams_id
    })
  }

  // ดึงข้อมูลการตั้งค่าระบบว่าจะใช้คอมมิชชั่นหรือไม่
  const getDocsSettings = () => {
    documentSetting().then(res => {
      commissionStatus.value = res.commission_id == '1'
      cashbackSelect.value = res.cashback_id
      leftBlockLoading.value = false
    })
  }

  // ดึงข้อมูลจำกีัดสิทธิ์ส่วนลด
  const getLimitDiscount = role => {
    limitDiscountGet(role).then(res => {
      limitDisCount.value = +res.role_shop_discount
      calLimitDisCount()
    })
  }

  // คำนวณส่วนลดสูงสุดต่อประเภทส่วนลดที่เลือก
  const calLimitDisCount = () => {
    if (discountType.value == 1) {
      calculateLimitDisCount.value = +limitDisCount.value
    } else {
      calculateLimitDisCount.value = (+totalPrice.value * +limitDisCount.value) / 100
    }
  }

  const checkDiscount = () => {
    if (discountType.value == 1) {
      totalAfterDiscount.value = (totalPrice.value - (totalPrice.value * discount.value) / 100) - +coupon_amount.value
    } else {
      totalAfterDiscount.value = (totalPrice.value - discount.value) - +coupon_amount.value
    }
    calculateTax()
  }

  const calculateDiscount = () => {
    if (+discount.value > +calculateLimitDisCount.value) {
      discount.value = +calculateLimitDisCount.value
    }
    checkDiscount()
  }

  const discountTypeChenage = () => {
    if (discountType.value == 1) {
      discount.value = (100 / +totalPrice.value) * +discount.value
      totalAfterDiscount.value = (totalPrice.value - (totalPrice.value * discount.value) / 100) - +coupon_amount.value
    } else {
      discount.value = (+totalPrice.value * +discount.value) / 100
      totalAfterDiscount.value = (totalPrice.value - discount.value) - +coupon_amount.value
    }
    calLimitDisCount()
    calculateTax()
  }


  const updateCashbackSelection = (item) => {
    coupon_amount.value = item.coupon_amount
    couponSelect.value = item
    calculateDiscount()
  }

  const calculateTax = () => {
    if (taxType.value == 1) {
      vat.value = 0
      totalValue.value = totalAfterDiscount.value
      totalPay.value = totalAfterDiscount.value
    } else if (taxType.value == 2) {
      const sum = (totalAfterDiscount.value * 7) / 107
      vat.value = sum
      totalValue.value = totalAfterDiscount.value - sum
      totalPay.value = totalValue.value + vat.value
    } else if (taxType.value == 3) {
      const sum = (totalAfterDiscount.value * 7) / 100
      vat.value = sum
      totalValue.value = totalAfterDiscount.value + sum
      totalPay.value = totalValue.value
    } else if (taxType.value == 4) {
      const sum = (totalAfterDiscount.value * 3) / 100
      vat.value = sum
      totalValue.value = totalAfterDiscount.value - sum
      totalPay.value = totalValue.value + vat.value
    } else if (taxType.value == 5) {
      const sum = (totalAfterDiscount.value * 3) / 100
      vat.value = sum
      totalValue.value = totalAfterDiscount.value + sum
      totalPay.value = totalValue.value``
    } else if (taxType.value == 6) {
      const sum = (totalAfterDiscount.value * 10) / 110
      vat.value = sum
      totalValue.value = totalAfterDiscount.value - sum
      totalPay.value = totalValue.value
    } else if (taxType.value == 7) {
      const sum = (totalAfterDiscount.value * 10) / 100
      vat.value = sum
      totalValue.value = totalAfterDiscount.value + sum
      totalPay.value = totalValue.value
    }
  }

  // ฟังก์ชั่นเริ่มต้นในการทำงาน
  onMounted(() => {
    addListEditToCart()
    getTeamList()
    setTimeout(() => {
      currentCurrency.value = localStorage.getItem('currency')
      if (currentCurrency.value == 2) {
        discountTypeList.value = [
          {
            label: '%',
            value: 1,
          },
          {
            label: i18n.t('USD'),
            value: 2,
          },
        ]
      } else {
        discountTypeList.value = [
          {
            label: '%',
            value: 1,
          },
          {
            label: i18n.t('bath'),
            value: 2,
          },
        ]
      }
    }, 1000)
  })

  const addListEditToCart = () => {
    cartLoading.value = true
    loading.value = true
    editReceiptAddListToCart({
      order_id_pri: router.currentRoute.params.id,
    }).then(() => {
      getReceiptEdit()
    })
  }

  // ดึงข้อมูลใบเสร็จที่จะแก้ไข
  const getReceiptEdit = () => {
    loading.value = true
    cartLoading.value = true
    editReceiptGet(router.currentRoute.params.id).then(res => {
      getCustomer(res.customer_id_pri)
      console.log('res', res)
      cartData.value = res
      cartList.value = res.cart
      date_create.value = moment(res.order_create).format('YYYY-MM-DD')
      orderId.value = res.order_id
      totalPrice.value = +res.order_price
      discount.value = +res.order_save_amount
      discountType.value = +res.order_save_id
      totalAfterDiscount.value = +res.order_befor_tax
      taxType.value = +res.order_tax_id
      vat.value = +res.order_tax
      totalValue.value = +res.order_total
      totalPay.value = +res.order_totalpay
      orderId.value = res.order_id
      coupon_amount.value = +res.order_coupon
      couponSelect.value.coupon_id = res.coupon_id
      loading.value = false
      cartLoading.value = false
      options.value.page = 1
      options.value.itemsPerPage = -1
      calLimitDisCount()
    })
  }

  // ลบสินค้าออกจากตะกร้า
  const removeCart = id => {
    removeLoading.value = true
    editReceiptRemoveCart(id).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      statusDelete.value = !statusDelete.value
      getReceiptEdit()
      removeLoading.value = false
    })
  }

  // ดึงข้อมูลูกค้า
  const getCustomer = id => {
    customerById(id).then(res => {
      customerData.value = res
      customerDrugList.value = res.drug
      getDocsSettings()
      getCoupon()
      leftBlockLoading.value = false
    })
  }

  const checkDisplay = (type, opd, opdchecking_id) => {
    if (type == 3) {
      if (opd == null) {
        return true
      } else {
        if (opdchecking_id == null) {
          return true
        } else {
          return false
        }
      }
    } else {
      return true
    }
  }

  // บันทึกใบเสร็จ
  const saveReceipt = () => {
    processing.value = true
    editReceiptSave({
      order_id_pri: router.currentRoute.params.id,
      order_price: totalPrice.value,
      order_save_id: discountType.value,
      order_save_amount: discount.value || 0,
      order_save: discountType.value == 1 ? (+totalPrice.value * +discount.value) / 100 : discount.value,
      order_befor_tax: totalAfterDiscount.value,
      order_tax_id: taxType.value,
      order_tax: vat.value,
      order_total: totalValue.value,
      order_totalpay: totalPay.value,
      order_deposit_id: cartData.value.order_deposit_id,
      order_deposit_amount: cartData.value.order_deposit_id == '1' ? cartData.value.order_deposit_amount : 0,
      order_deposit_bank_id: cartData.value.order_deposit_bank_id,
      order_deposit_teams_id: cartData.value.order_deposit_teams_id,
      coupon_id: couponSelect.value.coupon_id || 0,
      order_coupon: coupon_amount.value,
      order_create: date_create.value,
      lang: i18n.locale,
    }).then(res => {
      if (res.response) {
        store.commit('app/ALERT', {
          message: res.message,
          color: 'success',
        })
        router.push({
          name: 'order-detail',
          params: { id: router.currentRoute.params.id },
        })
      } else {
        store.commit('app/ALERT', {
          message: res.message,
          color: 'error',
        })
        processing.value = false
      }
    })
  }

  watch([discount], value => {
    if (value == '') {
      discount.value = 0
    }
  })

  return {
    updateCashbackSelection,
    isShowCashbackList,
    listCoupon,
    couponSelect,
    coupon_amount,
    tab,
    customerData,
    orderId,
    leftBlockLoading,
    date_create,
    isShowDate,
    cartLoading,
    sumLoading,
    cartList,
    totalPrice,
    discount,
    discountType,
    totalAfterDiscount,
    taxType,
    vat,
    totalValue,
    totalPay,
    options,
    columns,
    limitDisCount,
    calculateLimitDisCount,
    taxTypeList,
    discountTypeList,
    commissionStatus,
    teamsAndUserList,
    removeLoading,
    isEditCartDrug,
    dataEdit,
    statusDelete,
    processingDeposit,
    processing,
    isPayment,
    isDeposit,

    // payment
    channelPayList,
    order_comment,
    typePayment,
    channelPaySelect,
    commissionSelect,
    payAmount,
    statusPoint,
    orderPointsave,
    depositAmount,
    cashbackSelect,
    cancelCashback,
    removeCart,
    getReceiptEdit,
    currentCurrency,
    calLimitDisCount,
    calculateDiscount,
    discountTypeChenage,
    calculateTax,
    saveReceipt,
    cartData,
    customerDrugList,
    checkDisplay,
  }
}
